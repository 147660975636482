[data-tag="allowRowEvents"] {
  white-space: pre-wrap!important;
}

[data-tag="allowRowEvents"] span {
  white-space: pre-wrap!important;
  width: 25vw;
}


.w_menu .nav-item .nav-link{
    color:black !important;
}

.text_box input{
    height: 50px !important;
}

.login-form .text_box .text_c {
    color: #161341;
    font-size: 14px;
    margin-bottom: 7px !important;
}

.sign_info .login-form .text_box {
    margin-bottom: 15px !important;
}

.alert_sign {
  width: 30%;
  float: right;
  z-index: 10;
  position:inherit;
}

.btn-a-a{
  color:white;
}

.ticket-text{
  width: 60% !important;
  line-height: 1.6 !important;
}

.partner-card-input{
  width: 50% !important;
  text-align:center;
}

.rccs__card--unknown > div {
  background: linear-gradient(
25deg, #d31212, #999) !important;
}

.yarr{
  margin-left:auto;
  margin-right: auto;
  width: 50%;
}
.btn_three2 {
  font: 500 14px
"Poppins", sans-serif;
  color: #fff;
  background: #7444fd;
  border-radius: 3px;
  padding: 15px 30px;
  border: 1px solid #7444fd;
  transition: all 0.3s linear;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.toggle-2 {
  text-align: center;
}

.rccs__card--unknown div{
}

.rccs__chip{
  color:blue;
}

.btn_three2 {
    font: 500 14px "Poppins", sans-serif;
    color: #fff;
    background: #7444fd;
    border-radius: 3px;
    padding: 15px 30px;
    border: 1px solid #7444fd;
    margin-top: 10px;
    transition: all 0.3s linear;
    cursor: pointer;
    display: inline-block;
}

.pf-c-select {
    --pf-global--Color--100: var(--pf-global--Color--dark-100);
    --pf-global--Color--200: var(--pf-global--Color--dark-200);
    --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
    --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
    --pf-global--link--Color: var(--pf-global--link--Color--dark);
    --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
    --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
  }
  
  .pf-c-select {
    color: var(--pf-global--Color--100);
    --pf-c-select__toggle--PaddingTop: var(--pf-global--spacer--form-element);
    --pf-c-select__toggle--PaddingRight: var(--pf-global--spacer--sm);
    --pf-c-select__toggle--PaddingBottom: var(--pf-global--spacer--form-element);
    --pf-c-select__toggle--PaddingLeft: var(--pf-global--spacer--sm);
    --pf-c-select__toggle--MinWidth: var(--pf-global--target-size--MinWidth);
    --pf-c-select__toggle--FontSize: var(--pf-global--FontSize--md);
    --pf-c-select__toggle--FontWeight: var(--pf-global--FontWeight--normal);
    --pf-c-select__toggle--LineHeight: var(--pf-global--LineHeight--md);
    --pf-c-select__toggle--BackgroundColor: var(--pf-global--BackgroundColor--100);
    --pf-c-select__toggle--before--BorderTopWidth: var(--pf-global--BorderWidth--sm);
    --pf-c-select__toggle--before--BorderRightWidth: var(--pf-global--BorderWidth--sm);
    --pf-c-select__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
    --pf-c-select__toggle--before--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
    --pf-c-select__toggle--before--BorderWidth: var(--pf-c-select__toggle--before--BorderTopWidth) var(--pf-c-select__toggle--before--BorderRightWidth) var(--pf-c-select__toggle--before--BorderBottomWidth) var(--pf-c-select__toggle--before--BorderLeftWidth);
    --pf-c-select__toggle--before--BorderTopColor: var(--pf-global--BorderColor--300);
    --pf-c-select__toggle--before--BorderRightColor: var(--pf-global--BorderColor--300);
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-global--BorderColor--200);
    --pf-c-select__toggle--before--BorderLeftColor: var(--pf-global--BorderColor--300);
    --pf-c-select__toggle--Color: var(--pf-global--Color--100);
    --pf-c-select__toggle--hover--before--BorderBottomColor: var(--pf-global--active-color--100);
    --pf-c-select__toggle--focus--before--BorderBottomColor: var(--pf-global--active-color--100);
    --pf-c-select__toggle--focus--before--BorderBottomWidth: var(--pf-global--BorderWidth--md);
    --pf-c-select__toggle--active--before--BorderBottomColor: var(--pf-global--active-color--100);
    --pf-c-select__toggle--active--before--BorderBottomWidth: var(--pf-global--BorderWidth--md);
    --pf-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--active-color--100);
    --pf-c-select__toggle--m-expanded--before--BorderBottomWidth: var(--pf-global--BorderWidth--md);
    --pf-c-select__toggle--disabled--BackgroundColor: var(--pf-global--disabled-color--300);
    --pf-c-select__toggle--m-plain--before--BorderColor: transparent;
    --pf-c-select__toggle--m-placeholder--Color: transparent;
    --pf-c-select--m-invalid__toggle--before--BorderBottomColor: var(--pf-global--danger-color--100);
    --pf-c-select--m-invalid__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--md);
    --pf-c-select--m-invalid__toggle--hover--before--BorderBottomColor: var(--pf-global--danger-color--100);
    --pf-c-select--m-invalid__toggle--focus--before--BorderBottomColor: var(--pf-global--danger-color--100);
    --pf-c-select--m-invalid__toggle--active--before--BorderBottomColor: var(--pf-global--danger-color--100);
    --pf-c-select--m-invalid__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--danger-color--100);
    --pf-c-select--m-invalid__toggle-status-icon--Color: var(--pf-global--danger-color--100);
    --pf-c-select--m-success__toggle--before--BorderBottomColor: var(--pf-global--success-color--100);
    --pf-c-select--m-success__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--md);
    --pf-c-select--m-success__toggle--hover--before--BorderBottomColor: var(--pf-global--success-color--100);
    --pf-c-select--m-success__toggle--focus--before--BorderBottomColor: var(--pf-global--success-color--100);
    --pf-c-select--m-success__toggle--active--before--BorderBottomColor: var(--pf-global--success-color--100);
    --pf-c-select--m-success__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--success-color--100);
    --pf-c-select--m-success__toggle-status-icon--Color: var(--pf-global--success-color--100);
    --pf-c-select--m-warning__toggle--before--BorderBottomColor: var(--pf-global--warning-color--100);
    --pf-c-select--m-warning__toggle--before--BorderBottomWidth: var(--pf-global--BorderWidth--md);
    --pf-c-select--m-warning__toggle--hover--before--BorderBottomColor: var(--pf-global--warning-color--100);
    --pf-c-select--m-warning__toggle--focus--before--BorderBottomColor: var(--pf-global--warning-color--100);
    --pf-c-select--m-warning__toggle--active--before--BorderBottomColor: var(--pf-global--warning-color--100);
    --pf-c-select--m-warning__toggle--m-expanded--before--BorderBottomColor: var(--pf-global--warning-color--100);
    --pf-c-select--m-warning__toggle-status-icon--Color: var(--pf-global--warning-color--100);
    --pf-c-select__toggle-wrapper--not-last-child--MarginRight: var(--pf-global--spacer--xs);
    --pf-c-select__toggle-wrapper--MaxWidth: calc(100% - var(--pf-global--spacer--lg));
    --pf-c-select__toggle-wrapper--c-chip-group--MarginTop: 0.3125rem;
    --pf-c-select__toggle-wrapper--c-chip-group--MarginBottom: 0.3125rem;
    --pf-c-select__toggle-typeahead--FlexBasis: 10em;
    --pf-c-select__toggle-typeahead--BackgroundColor: transparent;
    --pf-c-select__toggle-typeahead--BorderTop: var(--pf-global--BorderWidth--sm) solid transparent;
    --pf-c-select__toggle-typeahead--BorderRight: none;
    --pf-c-select__toggle-typeahead--BorderLeft: none;
    --pf-c-select__toggle-typeahead--MinWidth: 7.5rem;
    --pf-c-select__toggle-typeahead--focus--PaddingBottom: calc(var(--pf-global--spacer--form-element) - var(--pf-global--BorderWidth--md));
    --pf-c-select__toggle--m-placeholder__toggle-text--Color: var(--pf-global--Color--dark-200);
    --pf-c-select__toggle-icon--toggle-text--MarginLeft: var(--pf-global--spacer--xs);
    --pf-c-select__toggle-badge--PaddingLeft: var(--pf-global--spacer--sm);
    --pf-c-select__toggle-status-icon--MarginLeft: var(--pf-global--spacer--xs);
    --pf-c-select__toggle-status-icon--Color: var(--pf-global--Color--100);
    --pf-c-select__toggle-arrow--MarginLeft: var(--pf-global--spacer--md);
    --pf-c-select__toggle-arrow--MarginRight: var(--pf-global--spacer--sm);
    --pf-c-select__toggle-arrow--with-clear--MarginLeft: var(--pf-global--spacer--sm);
    --pf-c-select__toggle-arrow--m-top--m-expanded__toggle-arrow--Rotate: 180deg;
    --pf-c-select--m-plain__toggle-arrow--Color: var(--pf-global--Color--200);
    --pf-c-select--m-plain--hover__toggle-arrow--Color: var(--pf-global--Color--100);
    --pf-c-select__toggle-clear--PaddingRight: var(--pf-global--spacer--sm);
    --pf-c-select__toggle-clear--PaddingLeft: var(--pf-global--spacer--md);
    --pf-c-select__toggle-clear--toggle-button--PaddingLeft: var(--pf-global--spacer--sm);
    --pf-c-select__toggle-button--Color: var(--pf-global--Color--100);
    --pf-c-select__menu--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
    --pf-c-select__menu--BoxShadow: var(--pf-global--BoxShadow--md);
    --pf-c-select__menu--PaddingTop: var(--pf-global--spacer--sm);
    --pf-c-select__menu--PaddingBottom: var(--pf-global--spacer--sm);
    --pf-c-select__menu--Top: calc(100% + var(--pf-global--spacer--xs));
    --pf-c-select__menu--ZIndex: var(--pf-global--ZIndex--sm);
    --pf-c-select__menu--Width: auto;
    --pf-c-select__menu--MinWidth: 100%;
    --pf-c-select__menu--m-top--TranslateY: calc(-100% - var(--pf-global--spacer--xs));
    --pf-c-select__list-item--m-loading--PaddingTop: var(--pf-global--spacer--sm);
    --pf-c-select__menu-item--PaddingTop: var(--pf-global--spacer--sm);
    --pf-c-select__menu-item--PaddingRight: var(--pf-global--spacer--md);
    --pf-c-select__menu-item--m-selected--PaddingRight: var(--pf-global--spacer--2xl);
    --pf-c-select__menu-item--PaddingBottom: var(--pf-global--spacer--sm);
    --pf-c-select__menu-item--PaddingLeft: var(--pf-global--spacer--md);
    --pf-c-select__menu-item--FontSize: var(--pf-global--FontSize--md);
    --pf-c-select__menu-item--FontWeight: var(--pf-global--FontWeight--normal);
    --pf-c-select__menu-item--LineHeight: var(--pf-global--LineHeight--md);
    --pf-c-select__menu-item--Color: var(--pf-global--Color--dark-100);
    --pf-c-select__menu-item--disabled--Color: var(--pf-global--Color--dark-200);
    --pf-c-select__menu-item--Width: 100%;
    --pf-c-select__menu-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
    --pf-c-select__menu-item--focus--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
    --pf-c-select__menu-item--disabled--BackgroundColor: transparent;
    --pf-c-select__menu-item--m-link--Width: auto;
    --pf-c-select__menu-item--m-link--hover--BackgroundColor: transparent;
    --pf-c-select__menu-item--m-link--focus--BackgroundColor: transparent;
    --pf-c-select__menu-item--m-action--Color: var(--pf-global--Color--200);
    --pf-c-select__menu-item--m-action--hover--Color: var(--pf-global--Color--100);
    --pf-c-select__menu-item--m-action--focus--Color: var(--pf-global--Color--100);
    --pf-c-select__menu-item--m-action--disabled--Color: var(--pf-global--disabled-color--200);
    --pf-c-select__menu-item--m-action--Width: auto;
    --pf-c-select__menu-item--m-action--FontSize: var(--pf-global--icon--FontSize--sm);
    --pf-c-select__menu-item--m-action--hover--BackgroundColor: transparent;
    --pf-c-select__menu-item--m-action--focus--BackgroundColor: transparent;
    --pf-c-select__menu-item--hover__menu-item--m-action--Color: var(--pf-global--Color--200);
    --pf-c-select__menu-item--m-favorite-action--Color: var(--pf-global--Color--200);
    --pf-c-select__menu-item--m-favorite-action--hover--Color: var(--pf-global--Color--100);
    --pf-c-select__menu-item--m-favorite-action--focus--Color: var(--pf-global--Color--100);
    --pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--Color: var(--pf-global--palette--gold-400);
    --pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--hover--Color: var(--pf-global--palette--gold-500);
    --pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--focus--Color: var(--pf-global--palette--gold-500);
    --pf-c-select__menu-item--m-load--Color: var(--pf-global--link--Color);
    --pf-c-select__menu-item-icon--Color: var(--pf-global--active-color--100);
    --pf-c-select__menu-item-icon--FontSize: var(--pf-global--icon--FontSize--sm);
    --pf-c-select__menu-item-icon--Right: var(--pf-global--spacer--md);
    --pf-c-select__menu-item-icon--Top: 50%;
    --pf-c-select__menu-item-icon--TranslateY: -50%;
    --pf-c-select__menu-item-action-icon--MinHeight: calc(var(--pf-c-select__menu-item--FontSize) * var(--pf-c-select__menu-item--LineHeight));
    --pf-c-select__menu-item--match--FontWeight: var(--pf-global--FontWeight--bold);
    --pf-c-select__menu-search--PaddingTop: var(--pf-global--spacer--sm);
    --pf-c-select__menu-search--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
    --pf-c-select__menu-search--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-select__menu-search--PaddingLeft: var(--pf-c-select__menu-item--PaddingLeft);
    --pf-c-select__menu-group--menu-group--PaddingTop: var(--pf-global--spacer--sm);
    --pf-c-select__menu-group-title--PaddingTop: var(--pf-c-select__menu-item--PaddingTop);
    --pf-c-select__menu-group-title--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
    --pf-c-select__menu-group-title--PaddingBottom: var(--pf-c-select__menu-item--PaddingBottom);
    --pf-c-select__menu-group-title--PaddingLeft: var(--pf-c-select__menu-item--PaddingLeft);
    --pf-c-select__menu-group-title--FontSize: var(--pf-global--FontSize--sm);
    --pf-c-select__menu-group-title--FontWeight: var(--pf-global--FontWeight--semi-bold);
    --pf-c-select__menu-group-title--Color: var(--pf-global--Color--dark-200);
    --pf-c-select__menu-item-count--MarginLeft: var(--pf-global--spacer--md);
    --pf-c-select__menu-item-count--FontSize: var(--pf-global--FontSize--sm);
    --pf-c-select__menu-item-count--Color: var(--pf-global--Color--200);
    --pf-c-select__menu-item--disabled__menu-item-count--Color: var(--pf-global--Color--dark-200);
    --pf-c-select__menu-item-description--FontSize: var(--pf-global--FontSize--xs);
    --pf-c-select__menu-item-description--Color: var(--pf-global--Color--200);
    --pf-c-select__menu-item-description--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
    --pf-c-select__menu-item-main--PaddingRight: var(--pf-c-select__menu-item--PaddingRight);
    --pf-c-select__menu-item--m-selected__menu-item-main--PaddingRight: var(--pf-c-select__menu-item--m-selected--PaddingRight);
    --pf-c-select__menu-footer--BoxShadow: var(--pf-global--BoxShadow--sm-top);
    --pf-c-select__menu-footer--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-select__menu-footer--PaddingRight: var(--pf-global--spacer--md);
    --pf-c-select__menu-footer--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-select__menu-footer--PaddingLeft: var(--pf-global--spacer--md);
    --pf-c-select__menu-footer--MarginTop: var(--pf-global--spacer--sm);
    --pf-c-select__menu-footer--MarginBottom: calc(var(--pf-global--spacer--sm) * -1);
    --pf-c-select-menu--c-divider--MarginTop: var(--pf-global--spacer--sm);
    --pf-c-select-menu--c-divider--MarginBottom: var(--pf-global--spacer--sm);
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .pf-c-select .pf-c-divider {
    margin-top: var(--pf-c-select-menu--c-divider--MarginTop);
    margin-bottom: var(--pf-c-select-menu--c-divider--MarginBottom);
  }
  .pf-c-select .pf-c-divider:last-child {
    --pf-c-select-menu--c-divider--MarginBottom: 0;
  }
  .pf-c-select.pf-m-invalid {
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-c-select--m-invalid__toggle--before--BorderBottomColor);
    --pf-c-select__toggle--before--BorderBottomWidth: var(--pf-c-select--m-invalid__toggle--before--BorderBottomWidth);
    --pf-c-select__toggle--hover--before--BorderBottomColor: var(--pf-c-select--m-invalid__toggle--hover--before--BorderBottomColor);
    --pf-c-select__toggle--focus--before--BorderBottomColor: var(--pf-c-select--m-invalid__toggle--focus--before--BorderBottomColor);
    --pf-c-select__toggle--active--before--BorderBottomColor: var(--pf-c-select--m-invalid__toggle--active--before--BorderBottomColor);
    --pf-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-c-select--m-invalid__toggle--m-expanded--before--BorderBottomColor);
    --pf-c-select__toggle-status-icon--Color: var(--pf-c-select--m-invalid__toggle-status-icon--Color);
  }
  .pf-c-select.pf-m-success {
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-c-select--m-success__toggle--before--BorderBottomColor);
    --pf-c-select__toggle--before--BorderBottomWidth: var(--pf-c-select--m-success__toggle--before--BorderBottomWidth);
    --pf-c-select__toggle--hover--before--BorderBottomColor: var(--pf-c-select--m-success__toggle--hover--before--BorderBottomColor);
    --pf-c-select__toggle--focus--before--BorderBottomColor: var(--pf-c-select--m-success__toggle--focus--before--BorderBottomColor);
    --pf-c-select__toggle--active--before--BorderBottomColor: var(--pf-c-select--m-success__toggle--active--before--BorderBottomColor);
    --pf-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-c-select--m-success__toggle--m-expanded--before--BorderBottomColor);
    --pf-c-select__toggle-status-icon--Color: var(--pf-c-select--m-success__toggle-status-icon--Color);
  }
  .pf-c-select.pf-m-warning {
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-c-select--m-warning__toggle--before--BorderBottomColor);
    --pf-c-select__toggle--before--BorderBottomWidth: var(--pf-c-select--m-warning__toggle--before--BorderBottomWidth);
    --pf-c-select__toggle--hover--before--BorderBottomColor: var(--pf-c-select--m-warning__toggle--hover--before--BorderBottomColor);
    --pf-c-select__toggle--focus--before--BorderBottomColor: var(--pf-c-select--m-warning__toggle--focus--before--BorderBottomColor);
    --pf-c-select__toggle--active--before--BorderBottomColor: var(--pf-c-select--m-warning__toggle--active--before--BorderBottomColor);
    --pf-c-select__toggle--m-expanded--before--BorderBottomColor: var(--pf-c-select--m-warning__toggle--m-expanded--before--BorderBottomColor);
    --pf-c-select__toggle-status-icon--Color: var(--pf-c-select--m-warning__toggle-status-icon--Color);
  }
  
  .pf-c-select__menu-search + .pf-c-divider {
    --pf-c-select-menu--c-divider--MarginTop: 0;
  }
  
  .pf-c-select__toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: var(--pf-c-select__toggle--MinWidth);
    padding: var(--pf-c-select__toggle--PaddingTop) var(--pf-c-select__toggle--PaddingRight) var(--pf-c-select__toggle--PaddingBottom) var(--pf-c-select__toggle--PaddingLeft);
    font-size: var(--pf-c-select__toggle--FontSize);
    font-weight: var(--pf-c-select__toggle--FontWeight);
    line-height: var(--pf-c-select__toggle--LineHeight);
    color: var(--pf-c-select__toggle--Color);
    white-space: nowrap;
    cursor: pointer;
    background-color: var(--pf-c-select__toggle--BackgroundColor);
    border: none;
  }
  .pf-c-select__toggle.pf-m-disabled, .pf-c-select__toggle:disabled {
    --pf-c-select__toggle--BackgroundColor: var(--pf-c-select__toggle--disabled--BackgroundColor);
    pointer-events: none;
  }
  .pf-c-select__toggle.pf-m-disabled::before, .pf-c-select__toggle:disabled::before {
    border: 0;
  }
  .pf-c-select__toggle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    border-color: var(--pf-c-select__toggle--before--BorderTopColor) var(--pf-c-select__toggle--before--BorderRightColor) var(--pf-c-select__toggle--before--BorderBottomColor) var(--pf-c-select__toggle--before--BorderLeftColor);
    border-style: solid;
    border-width: var(--pf-c-select__toggle--before--BorderWidth);
  }
  .pf-c-select__toggle:hover {
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-c-select__toggle--hover--before--BorderBottomColor);
  }
  .pf-c-select__toggle:focus, .pf-c-select__toggle:focus-within {
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-c-select__toggle--focus--before--BorderBottomColor);
    --pf-c-select__toggle--before--BorderBottomWidth: var(--pf-c-select__toggle--focus--before--BorderBottomWidth);
  }
  .pf-c-select__toggle:active, .pf-c-select__toggle.pf-m-active {
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-c-select__toggle--active--before--BorderBottomColor);
    --pf-c-select__toggle--before--BorderBottomWidth: var(--pf-c-select__toggle--active--before--BorderBottomWidth);
  }
  .pf-m-expanded > .pf-c-select__toggle {
    --pf-c-select__toggle--before--BorderBottomColor: var(--pf-c-select__toggle--m-expanded--before--BorderBottomColor);
    --pf-c-select__toggle--before--BorderBottomWidth: var(--pf-c-select__toggle--m-expanded--before--BorderBottomWidth);
  }
  .pf-c-select__toggle.pf-m-plain {
    --pf-c-select__toggle-arrow--Color: var(--pf-c-select--m-plain__toggle-arrow--Color);
  }
  .pf-c-select__toggle.pf-m-plain::before {
    border-color: var(--pf-c-select__toggle--m-plain--before--BorderColor);
  }
  .pf-c-select__toggle.pf-m-plain:hover, .pf-c-select__toggle.pf-m-plain:active, .pf-c-select__toggle.pf-m-plain.pf-m-active, .pf-c-select__toggle.pf-m-plain:focus, .pf-c-select.pf-m-expanded > .pf-c-select__toggle.pf-m-plain {
    --pf-c-select--m-plain__toggle-arrow--Color: var(--pf-c-select--m-plain--hover__toggle-arrow--Color);
  }
  .pf-c-select__toggle.pf-m-typeahead {
    --pf-c-select__toggle--PaddingTop: 0;
    --pf-c-select__toggle--PaddingRight: 0;
    --pf-c-select__toggle--PaddingBottom: 0;
  }
  .pf-c-select__toggle.pf-m-typeahead .pf-c-form-control {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    --pf-c-form-control--invalid--BackgroundUrl: none;
    position: relative;
    height: auto;
  }
  .pf-c-select__toggle .pf-c-select__toggle-clear {
    padding-right: var(--pf-c-select__toggle-clear--PaddingRight);
    padding-left: var(--pf-c-select__toggle-clear--PaddingLeft);
    margin-left: auto;
  }
  .pf-c-select__toggle .pf-c-select__toggle-button {
    color: var(--pf-c-select__toggle-button--Color);
  }
  .pf-c-select__toggle .pf-c-select__toggle-clear + .pf-c-select__toggle-button {
    padding-left: var(--pf-c-select__toggle-clear--toggle-button--PaddingLeft);
  }
  .pf-c-select__toggle.pf-m-placeholder {
    --pf-c-select__toggle-text--Color: var(--pf-c-select__toggle--m-placeholder__toggle-text--Color);
  }
  
  .pf-c-select__toggle-arrow {
    color: var(--pf-c-select__toggle-arrow--Color, inherit);
  }
  * + .pf-c-select__toggle-arrow {
    margin-right: var(--pf-c-select__toggle-arrow--MarginRight);
    margin-left: var(--pf-c-select__toggle-arrow--MarginLeft);
  }
  .pf-c-select.pf-m-top.pf-m-expanded .pf-c-select__toggle-arrow {
    transform: rotate(var(--pf-c-select__toggle-arrow--m-top--m-expanded__toggle-arrow--Rotate));
  }
  
  .pf-c-select__toggle-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--pf-c-select__toggle-text--Color, inherit);
  }
  
  .pf-c-select__toggle-wrapper {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    min-width: 0;
    max-width: var(--pf-c-select__toggle-wrapper--MaxWidth);
    white-space: normal;
  }
  .pf-c-select__toggle-wrapper > :not(:last-child) {
    margin-right: var(--pf-c-select__toggle-wrapper--not-last-child--MarginRight);
  }
  .pf-c-select__toggle-wrapper > .pf-c-form-control {
    margin-top: calc(-1 * var(--pf-c-select__toggle-wrapper--m-typeahead--PaddingTop));
  }
  .pf-c-select__toggle-wrapper .pf-c-chip-group {
    margin-top: var(--pf-c-select__toggle-wrapper--c-chip-group--MarginTop);
    margin-bottom: var(--pf-c-select__toggle-wrapper--c-chip-group--MarginBottom);
  }
  .pf-c-select__toggle-wrapper > .pf-c-select__toggle-typeahead:first-child {
    margin-left: calc(-1 * var(--pf-c-select__toggle--PaddingLeft));
  }
  
  .pf-c-select__toggle-icon + .pf-c-select__toggle-text {
    margin-left: var(--pf-c-select__toggle-icon--toggle-text--MarginLeft);
  }
  
  .pf-c-select__toggle-status-icon {
    margin-left: var(--pf-c-select__toggle-status-icon--MarginLeft);
    color: var(--pf-c-select__toggle-status-icon--Color);
  }
  
  .pf-c-select__toggle-badge {
    display: flex;
    padding-left: var(--pf-c-select__toggle-badge--PaddingLeft);
  }
  
  .pf-c-select__toggle-typeahead {
    flex-basis: var(--pf-c-select__toggle-typeahead--FlexBasis);
    flex-grow: 1;
    min-width: var(--pf-c-select__toggle-typeahead--MinWidth);
    flex-shrink: 0;
  }
  .pf-c-select__toggle-typeahead.pf-c-form-control {
    background-color: var(--pf-c-select__toggle-typeahead--BackgroundColor);
    border-top: var(--pf-c-select__toggle-typeahead--BorderTop);
    border-right: var(--pf-c-select__toggle-typeahead--BorderRight);
    border-bottom-color: transparent;
    border-left: var(--pf-c-select__toggle-typeahead--BorderLeft);
  }
  .pf-c-select__toggle-typeahead.pf-c-form-control:focus {
    padding-bottom: var(--pf-c-select__toggle-typeahead--focus--PaddingBottom);
  }
  
  .pf-c-select__menu {
    position: absolute;
    top: var(--pf-c-select__menu--Top);
    z-index: var(--pf-c-select__menu--ZIndex);
    width: var(--pf-c-select__menu--Width);
    min-width: var(--pf-c-select__menu--MinWidth);
    padding-top: var(--pf-c-select__menu--PaddingTop);
    padding-bottom: var(--pf-c-select__menu--PaddingBottom);
    background-color: var(--pf-c-select__menu--BackgroundColor);
    background-clip: padding-box;
    box-shadow: var(--pf-c-select__menu--BoxShadow);
  }
  .pf-c-select__menu.pf-m-align-right {
    right: 0;
  }
  .pf-c-select.pf-m-top .pf-c-select__menu {
    top: 0;
    transform: translateY(var(--pf-c-select__menu--m-top--TranslateY));
  }
  
  .pf-c-select__menu-fieldset {
    border: 0;
  }
  
  .pf-c-select__menu-wrapper {
    display: flex;
  }
  .pf-c-select__menu-wrapper.pf-m-favorite .pf-c-select__menu-item.pf-m-favorite-action {
    --pf-c-select__menu-item--m-favorite-action--Color: var(--pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--Color);
    --pf-c-select__menu-item--m-favorite-action--hover--Color: var(--pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--hover--Color);
    --pf-c-select__menu-item--m-favorite-action--focus--Color: var(--pf-c-select__menu-wrapper--m-favorite__menu-item--m-favorite-action--focus--Color);
  }
  
  .pf-c-select__menu-item {
    position: relative;
    width: var(--pf-c-select__menu-item--Width);
    padding: var(--pf-c-select__menu-item--PaddingTop) var(--pf-c-select__menu-item--PaddingRight) var(--pf-c-select__menu-item--PaddingBottom) var(--pf-c-select__menu-item--PaddingLeft);
    font-size: var(--pf-c-select__menu-item--FontSize);
    font-weight: var(--pf-c-select__menu-item--FontWeight);
    line-height: var(--pf-c-select__menu-item--LineHeight);
    color: var(--pf-c-select__menu-item--Color);
    text-align: left;
    white-space: nowrap;
    background-color: transparent;
    border: none;
  }
  .pf-c-select__menu-item:hover, .pf-c-select__menu-item:focus, .pf-c-select__menu-item.pf-m-focus {
    --pf-c-select__menu-item--m-action--Color: var(--pf-c-select__menu-item--hover__menu-item--m-action--Color);
    text-decoration: none;
  }
  .pf-c-select__menu-wrapper:hover, .pf-c-select__menu-item:hover {
    background-color: var(--pf-c-select__menu-item--hover--BackgroundColor);
  }
  
  .pf-c-select__menu-wrapper:focus-within,
  .pf-c-select__menu-wrapper.pf-m-focus, .pf-c-select__menu-item:focus, .pf-c-select__menu-item.pf-m-focus {
    position: relative;
    background-color: var(--pf-c-select__menu-item--focus--BackgroundColor);
  }
  
  .pf-c-select__menu-item.pf-m-link {
    --pf-c-select__menu-item--PaddingRight: 0;
    --pf-c-select__menu-item-main--PaddingRight: 0;
    --pf-c-select__menu-item-description--PaddingRight: 0;
    --pf-c-select__menu-item--Width: var(--pf-c-select__menu-item--m-link--Width);
    --pf-c-select__menu-item--hover--BackgroundColor: var(--pf-c-select__menu-item--m-link--hover--BackgroundColor);
    --pf-c-select__menu-item--focus--BackgroundColor: var(--pf-c-select__menu-item--m-link--focus--BackgroundColor);
    flex-grow: 1;
  }
  .pf-c-select__menu-item.pf-m-action {
    --pf-c-select__menu-item--Color: var(--pf-c-select__menu-item--m-action--Color);
    --pf-c-select__menu-item--Width: var(--pf-c-select__menu-item--m-action--Width);
    --pf-c-select__menu-item--hover--BackgroundColor: var(--pf-c-select__menu-item--m-action--hover--BackgroundColor);
    --pf-c-select__menu-item--focus--BackgroundColor: var(--pf-c-select__menu-item--m-action--focus--BackgroundColor);
    display: flex;
    align-items: flex-start;
    font-size: var(--pf-c-select__menu-item--m-action--FontSize);
  }
  .pf-c-select__menu-item.pf-m-action:hover {
    --pf-c-select__menu-item--m-action--Color: var(--pf-c-select__menu-item--m-action--hover--Color);
  }
  .pf-c-select__menu-item.pf-m-action:focus {
    --pf-c-select__menu-item--m-action--Color: var(--pf-c-select__menu-item--m-action--focus--Color);
  }
  .pf-c-select__menu-item.pf-m-action:disabled {
    --pf-c-select__menu-item--disabled--Color: var(--pf-c-select__menu-item--m-action--disabled--Color);
  }
  .pf-c-select__menu-item.pf-m-favorite-action {
    --pf-c-select__menu-item--m-action--Color: var(--pf-c-select__menu-item--m-favorite-action--Color);
    --pf-c-select__menu-item--m-action--hover--Color: var(--pf-c-select__menu-item--m-favorite-action--hover--Color);
    --pf-c-select__menu-item--m-action--focus--Color: var(--pf-c-select__menu-item--m-favorite-action--focus--Color);
  }
  .pf-c-select__menu-item.pf-m-selected {
    --pf-c-select__menu-item--PaddingRight: var(--pf-c-select__menu-item--m-selected--PaddingRight);
    --pf-c-select__menu-item-main--PaddingRight: var(--pf-c-select__menu-item--m-selected__menu-item-main--PaddingRight);
  }
  .pf-c-select__menu-item.pf-m-description {
    white-space: normal;
  }
  .pf-c-select__menu-item.pf-m-description:not(.pf-c-check) {
    --pf-c-select__menu-item--PaddingRight: 0;
  }
  .pf-c-select__menu-item.pf-m-description .pf-c-check__label {
    white-space: nowrap;
  }
  .pf-c-select__menu-wrapper.pf-m-disabled, .pf-c-select__menu-item:disabled, .pf-c-select__menu-item.pf-m-disabled {
    --pf-c-select__menu-item-count--Color: var(--pf-c-select__menu-item--disabled__menu-item-count--Color);
    color: var(--pf-c-select__menu-item--disabled--Color);
    pointer-events: none;
    background-color: var(--pf-c-select__menu-item--disabled--BackgroundColor);
  }
  
  .pf-c-select__menu-item.pf-c-check .pf-c-check__label {
    width: 100%;
  }
  .pf-c-select__menu-item.pf-m-load {
    --pf-c-select__menu-item--hover--BackgroundColor: transparent;
    --pf-c-select__menu-item--focus--BackgroundColor: transparent;
    --pf-c-select__menu-item--Color: var(--pf-c-select__menu-item--m-load--Color);
  }
  
  .pf-c-select__list-item.pf-m-loading {
    padding-top: var(--pf-c-select__list-item--m-loading--PaddingTop);
    text-align: center;
  }
  
  .pf-c-select__menu-item-main {
    position: relative;
    display: block;
    padding-right: var(--pf-c-select__menu-item-main--PaddingRight);
    white-space: nowrap;
  }
  
  .pf-c-select__menu-item-row {
    display: flex;
  }
  
  .pf-c-select__menu-item-text {
    flex-grow: 1;
  }
  
  .pf-c-select__menu-item-count {
    align-self: center;
    margin-left: var(--pf-c-select__menu-item-count--MarginLeft);
    font-size: var(--pf-c-select__menu-item-count--FontSize);
    color: var(--pf-c-select__menu-item-count--Color);
  }
  
  .pf-c-select__menu-item-description {
    display: block;
    padding-right: var(--pf-c-select__menu-item-description--PaddingRight);
    font-size: var(--pf-c-select__menu-item-description--FontSize);
    color: var(--pf-c-select__menu-item-description--Color);
  }
  
  .pf-c-select__menu-item-icon {
    position: absolute;
    top: var(--pf-c-select__menu-item-icon--Top);
    right: var(--pf-c-select__menu-item-icon--Right);
    font-size: var(--pf-c-select__menu-item-icon--FontSize);
    color: var(--pf-c-select__menu-item-icon--Color);
    transform: translateY(var(--pf-c-select__menu-item-icon--TranslateY));
  }
  
  .pf-c-select__menu-item-action-icon {
    display: flex;
    align-items: center;
    min-height: var(--pf-c-select__menu-item-action-icon--MinHeight);
  }
  
  .pf-c-select__menu-item--match {
    font-weight: var(--pf-c-select__menu-item--match--FontWeight);
    background-color: inherit;
  }
  
  .pf-c-select__menu-group + .pf-c-select__menu-group {
    padding-top: var(--pf-c-select__menu-group--menu-group--PaddingTop);
  }
  
  .pf-c-select__menu-search {
    padding: var(--pf-c-select__menu-search--PaddingTop) var(--pf-c-select__menu-search--PaddingRight) var(--pf-c-select__menu-search--PaddingBottom) var(--pf-c-select__menu-search--PaddingLeft);
  }
  
  .pf-c-select__menu-group-title {
    padding-top: var(--pf-c-select__menu-group-title--PaddingTop);
    padding-right: var(--pf-c-select__menu-group-title--PaddingRight);
    padding-bottom: var(--pf-c-select__menu-group-title--PaddingBottom);
    padding-left: var(--pf-c-select__menu-group-title--PaddingLeft);
    font-size: var(--pf-c-select__menu-group-title--FontSize);
    font-weight: var(--pf-c-select__menu-group-title--FontWeight);
    color: var(--pf-c-select__menu-group-title--Color);
  }
  
  .pf-c-select__menu-footer {
    padding: var(--pf-c-select__menu-footer--PaddingTop) var(--pf-c-select__menu-footer--PaddingRight) var(--pf-c-select__menu-footer--PaddingBottom) var(--pf-c-select__menu-footer--PaddingLeft);
    margin-top: var(--pf-c-select__menu-footer--MarginTop);
    margin-bottom: var(--pf-c-select__menu-footer--MarginBottom);
    box-shadow: var(--pf-c-select__menu-footer--BoxShadow);
  }
